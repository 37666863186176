<template>
  <div>    
    <div v-for="lastAccounts in json" v-bind:key=lastAccounts._ID class="lastAccounts shadow whitebg d-inline-block">      
      <ul>
        <li><b>Login : </b>{{ lastAccounts.login }}</li>
        <li><b>e-mail : </b>{{ lastAccounts.email }}&nbsp;<span style="cursor:pointer" @click="getAuthor(lastAccounts.email)">✉️</span></li>
        <li><b>Date création :  </b>{{ lastAccounts.time_id }}</li>
        <li><b>Dernière connexion :  </b>{{ lastAccounts.lastlogin }}</li>
        <li><b>Dernière IP : </b>{{ lastAccounts.lastip }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default { 
  name: 'lastCommAdmin',
  components: {
  },
  data() {         
    return {
      json: null, 
    }    
  },
  props: {
    accounts: {
        type: String,
        default: 10
    }
  },  
  methods: {
    getAuthor: function(email)  {      
      this.selectedDest = email      
      this.$emit('emailAuthor', email)
    },
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/lastaccounts/' + this.accounts)
    .then(response => (this.json = response.data.lastAccounts))    
  }
}

</script>

<style scoped>

.lastAccounts {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  text-align:left;
  width:320px;
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
}

ul {
  margin-left:0px;
}

li {
  list-style: square;
  margin-left:0px;
}
</style>