<template>
  <div>    
    <ModalAdmin v-if="editIt === true" @destroyModal="destroyModal" :movie="movieInModal" @saveOk="saveOk()"/>   
    <div v-for="lastMovies in json" v-bind:key=lastMovies._ID class="lastMovies whitebg shadow d-inline-block">      
      <span class="blue" v-on:click="modalIt($event, lastMovies.id, lastMovies.titre, lastMovies.support)">{{ lastMovies.titre }}</span> en {{ getSupport(lastMovies.support) }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import ModalAdmin from '@/components/ModalAdmin.vue'
export default { 
  name: 'lastMoviesAdmin',
  components: {
    ModalAdmin
  },
  data() {         
    return {
      json: null, 
      editIt: false,
      movieInModal: {}      
    }    
  },
  props: {
  },  
  methods: {
    getSupport(idSupp) {
      if(idSupp == "hddvd") {
        idSupp = "hd-dvd"
      }
      else if(idSupp == "bd3d") {
        idSupp = "bd 3d"
      }
      else if(idSupp == "bd4k") {
        idSupp = "bd 4k"
      }
      return idSupp.toUpperCase();
    },
    modalIt: function (e, id, titre, support) {
      this.movieInModal.id = id
      this.movieInModal.titre = titre
      this.movieInModal.support = support
      this.editIt = true
    },
    destroyModal: function() {
      this.editIt = false
      this.movieInModal = {}
    },
    saveOk: function(e) {
      this.$emit('saveOk')
    }
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/lastmovies')
    .then(response => (this.json = response.data.lastmovies))    
  }
}

</script>

<style scoped>

.lastMovies {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  width:200px;
  text-align: center;
}

div:hover {
  border-color:#007bff;
}

.blue:hover{
  cursor: pointer;
}

</style>