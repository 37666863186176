<template>
  <div>    
    <div v-for="lastPix in json" v-bind:key=lastPix._ID class="pix d-inline-block">      
      <router-link :to="{path: '/installation/' + urlProprio(lastPix.proprio)}"><v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + lastPix.ID" :alt="'Installation de ' + lastPix.proprio" class="shadow"/></router-link>        
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
export default { 
  name: 'lastPix',
  components: {
    VLazyImage
  },
  data() {         
    return {
      json: null, 
    }    
  },
  props: {
    image: {
        type: Number,
        default: 20
    }
  },  
  methods: {
    urlProprio: function(value) {
      return value.replace(/ /gi,'___')
    }
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/lastpix/' + this.image)
    .then(response => (this.json = response.data.lastPix))    
  }
}

</script>

<style scoped>

.pix {
  margin:2px;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>