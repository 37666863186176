<template>
  <div class="sendAnEmail whitebg shadow">    
    <form v-on:submit.prevent="checkMsg($event)">
      <div>
        <div v-if="error === true" class="red">
          Erreur recontrée !
        </div>      
        <div v-if="error === false" class="blue">
          Message envoyé avec succès !
        </div> 
        <h1>Destinataire :</h1>
        <input type="radio" id="single" value="single" v-model="picked" :checked="checked">
        <label for="one">Un destinataire</label>&nbsp;
        <input type="radio" id="more" value="more" v-model="picked">
        <label for="more">Tous les membres</label>
      </div>
      <div v-if="picked === 'single'">
        <h1>Adresse e-mail :</h1>
        <input type="email" v-model="email">
      </div>
      <div>
        <h1>Titre du message :</h1>        
        <input type="text" v-model="msgTitle">
      </div>
      <div v-if="picked !==null && msgTitle.trim() != ''">
        <h1>Contenu du message :</h1>
        <textarea v-model="message"/>
      </div>
      <div v-if="msgTitle.trim() != '' && message.trim() != ''" style="text-align:center">
        <button @click="checkMsg($event)" :disabled="stopped">Envoyer</button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
export default { 
  name: 'sendAnEmail',
  components: {
  },
  data() {         
    return {
      json: null, 
      picked: null,
      message: '',
      msgTitle: '',
      stopped: false,
      error :null,
      email: ''
    }    
  },
  props: {
    dest: {
        type: String,
        default: ''
    }
  },  
  computed: {        
    checked: function() {
      if(this.dest !== null && this.dest.trim() != '') {
        this.picked = "single"
        this.email = this.dest
        return true
      }
      else return false
    }
  },
  methods: {
    checkMsg: function(e) {      
      if(this.picked != null && this.msgTitle.trim() != '' && this.message.trim() != '') {
        this.postMsg()
      }
      else {
        this.error = true
      }
      e.preventDefault()
    },
    postMsg: function() {
      var apiUrl = "/backend/get/admin/sendmail"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({picked: this.picked, titre: this.msgTitle,email: this.email, message: this.message})
        })
        .then(res => res.json())
        .then(res => {
          if(res.retour != "ok") {
            this.error = true                        
          }
          else if(res.retour === "ok") {            
            this.error = false
            this.stopped = true
          }                       
        });
    }  
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {    
  }
}

</script>

<style scoped>

.sendAnEmail {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:10px;
  text-align:left;
}

a:hover {
  text-decoration: none;
}

h1 {
  font-size:1em;
  font-weight: bold;
  text-decoration: underline;
}

input {
  width:200px;
}

textarea {
  margin-top:10px;
  width:99%;
  height:20em;
}

.red{
  color:red;
}

.blue {
  color: #007bff;
}

</style>