<template>
  <div>      
    <div v-if="isEmpty(json)">
      <p>
        Aucun message à traiter !
      </p>
    </div>
    <div v-for="messages in json" v-bind:key=messages._ID class="whitebg messages shadow d-inline-block">      
      <form @submit.prevent="setOk($event, messages.id)" method="post">
        <input type="hidden" v-model="msgId[messages.id]" :run="setId(messages.id)" />
        <input type="text" :value="messages.auteur" disabled/><input type="mail" :value="messages.mail" disabled/><span style="cursor:pointer" @click="getAuthor(messages.mail)">✉️</span><input type="text" :value="messages.TIME" disabled/>
        <textarea :value="messages.message" disabled/>      
        <div>
          Commentaire : <input minlength="4" maxlength="128" type="text" v-model="commentaire[messages.id]" size="64"/>        
          <button @click="setOk($event, messages.id)" class="shadow">Traiter</button>        
        </div>        
      </form>
    </div>    
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default { 
  name: 'messagesAdmin',
  components: {
  },
  data() {         
    return {
      json: null, 
      commentaire: {},
      error: {},
      success: {},
      msgId: {},
      selectedDest: null,
    }    
  },      
  methods: {  
    getAuthor: function(email)  {      
      this.selectedDest = email      
      this.$emit('emailAuthor', email)
    },
    setId: function(id) {
      if(this.msgId[id] === null) {
        this.msgId[id] = id
      }      
    },
    setOk: function(e, id) {
      var apiUrl = "/backend/get/admin/setmessage"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({msgId: id,commentaire: this.commentaire[id]})
        })
        .then(res => res.json())
        .then(res => {
          let vm = this;
          if(res.post == "ok") {
            vm.success[id]  = true
            e.target.parentNode.parentNode.parentNode.parentNode.getElementsByTagName('div')[0].style.borderColor = "green"
            e.target.disabled = true
          }
          else {                        
            vm.error[id] = true    
            e.target.parentNode.parentNode.parentNode.parentNode.getElementsByTagName('div')[0].style.borderColor = "red"                                    
          }    
          //alert(id)
          //alert(this.error[id])                   
          //alert(this.success[id])
        })
      e.preventDefault()
    },
    isEmpty: function(obj) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
        return false;
        }
      }
      return true
    }
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/messages')
    .then(response => (this.json = response.data.messages))    
  }
}

</script>

<style scoped>

.messages {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  width:500px;
  text-align: center;
}

input {  
  margin: 5px;
}

textarea {
  margin:10px;
  width:95%;
}

button:disabled{
  color:#ccc;
}

</style>