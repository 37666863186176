<template>
  <div>      
    <div v-if="isEmpty(json)">
      <p>
        Aucune installation en attente à traiter !
      </p>
    </div>
    <div v-for="hiddenInstall in json" v-bind:key=hiddenInstall._ID class="whitebg hiddenInstall shadow d-inline-block">      
      <router-link :to="{path: '/presentation/' + urlProprio(hiddenInstall.Proprio)}">Installation Home-Cinema de {{ hiddenInstall.Proprio}}</router-link>      
    </div>    
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default { 
  name: 'hiddenInstall',
  components: {
  },
  data() {         
    return {
      json: null,
    }   
  },      
  methods: {  
    isEmpty: function(obj) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
        return false;
        }
      }
      return true
    },
    urlProprio: function(value) {
      return value.replace(/ /gi,'___')
    }
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/hiddeninstall')
    .then(response => (this.json = response.data.hiddenInstall))    
  }
}

</script>

<style scoped>

.hiddenInstall {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  width:320px;
  text-align: center;
}


</style>