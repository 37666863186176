<template>
  <div>    
    <div v-for="lastMarks in json" v-bind:key=lastMarks._ID class="lastMarks whitebg shadow d-inline-block">      
      {{ lastMarks.Texte }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default { 
  name: 'lastMarks',
  components: {
  },
  data() {         
    return {
      json: null, 
    }    
  },
  props: {
  },  
  methods: {
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/lastmarks')
    .then(response => (this.json = response.data.lastmarks))    
  }
}

</script>

<style scoped>

.lastMarks {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  width:200px;
  text-align: center;
}

div:hover {
  border-color:#007bff;
}

</style>