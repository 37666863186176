<template>
  <transition>
    <div class="modal show"  tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">    
        <div class="modal-content shadow">
          <div class="modal-header">
            <h5 class="modal-title">Edition</h5>            
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal($event)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>                    
          <div class="modal-body text-center">
            <div class="mx-auto">
              <p><label for="titre">Titre :</label><input name="titre" type="text" v-model="titre" :run="setTitre()" required/></p>
              <p><label for="ID">ID :</label><input name="id" disabled type="text" :value="movie.id"/></p>
              <p><label for="support">Support :</label><input name="support" disabled type="text" :value="getSupport(movie.support)"/></p>
              <p><label for="link">Lien Partenaire :</label><input name="link" type="url" pattern="https://.*" v-model="link"/></p>
            </div>
            <div class="text-center">  
              <div v-if="error === false" class="blue">Edition sauvegardée</div>
              <div v-else-if="error === true" class="red">Erreur lors de la sauvegarde</div>
              <button @click="chkEdit($event)" class="shadow button">Sauvegarder</button>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VLazyImage from 'v-lazy-image'
import axios from 'axios'
export default {
  name: 'modalAdmin',
  components: {    
    VLazyImage    
  },
  props: {
    movie : {
      type: Object,
      required: false,
      default: {}
    }
  },
  data() {         
    return {
      isShown: false,
      json: null,
      error: null,
      titre: '',     
      link:''
    }
  },
  mounted() {  
  },
  methods: {
    closeModal(e) {
      e.target.parentNode.parentNode.parentNode.parentNode.style.display="none"
      this.isShown = false
      this.$emit("destroyModal")
    },        
    chkEdit(e) {
      if( this.titre !== null && this.titre.trim() != "") {
        this.saveEdit()
      } 
      else {
          this.error = true          
      }     
      e.preventDefault()
    },   
    getSupport(idSupp) {
      if(idSupp == "hddvd") {
        idSupp = "hd-dvd"
      }
      else if(idSupp == "bd3d") {
        idSupp = "bd 3d"
      }
      else if(idSupp == "bd4k") {
        idSupp = "bd 4k"
      }
      return idSupp.toUpperCase();
    }, 
    setTitre() {
      if(this.titre.trim() === "") {
        this.titre = this.movie.titre.trim()
      }      
    },
    saveEdit() {
      var apiUrl = "/backend/get/admin/savemovie"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: this.movie.id,support: this.movie.support, titre: this.titre, link: this.link})
        })
        .then(res => res.json())
        .then(res => {
          if(res.retour != "ok") {
            this.error = true                        
          }
          else if(res.retour === "ok") {            
            this.error = false
            this.$emit('saveOk')
          }                       
        });
    }    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.show {
  display:block;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modal-header {
  border-bottom:0;
  color: #007bff;
  font-weight:bold;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.red {
  color: darkred;
}

label {
  width:100px;
  text-align: left;
}
</style>
