<template>
  <div class="adminPage container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Secret Admin Page !" 
      description="Page dédiée aux administrateurs du site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>

    <div v-if="disabled === false">
      <h1>Outils d'Administration</h1>            
      <div v-if="error === true" class="red">
        Erreur recontrée !
      </div>      
      <div v-if="error === false" class="blue">
        Message envoyé avec succès !
      </div>    

      <div>        
        <h2>Les derniers films ajoutés</h2>        
        <LastMoviesAdmin @saveOk="saveOk" :key="toto"/>
        <h2>Les dernières marques ajoutées</h2>
        <p>Edition à venir...</p>
        <LastMarksAdmin/>   
        <h2>Les messages à traiter</h2>
        <MessagesAdmin @emailAuthor="emailAuthor"/>
        <h2>Envoyer un e-mail</h2>
        <SendAnEmail :dest="dest"/>
        <h2>Les dernieres installations validées</h2>
        <LastInstallAdmin install="5"/>
        <h2>Les Installations non validées ou bloquées</h2>
        <HiddenInstallAdmin/>
        <h2>Les derniers commentaires postées</h2>
        <LastCommAdmin comm="10"/>
        <h2>Les derniers inscrits</h2>
        <LastAccountsAdmin accounts="10" @emailAuthor="emailAuthor"/>
        <h2>Les dernières photos postées</h2>
        <LastPix images="20"/>
        <h2>Le dernier récapitulatif envoyé</h2>
        <p>A venir...</p>        
      </div>
        
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import LastPix from '@/components/LastPix.vue'
import LastCommAdmin from '@/components/LastCommAdmin.vue'
import LastInstallAdmin from '@/components/LastInstallAdmin.vue'
import LastAccountsAdmin from '@/components/LastAccountsAdmin.vue'
import MessagesAdmin from '@/components/MessagesAdmin.vue'
import SendAnEmail from '@/components/SendAnEmail.vue'
import HiddenInstallAdmin from '@/components/HiddenInstallAdmin.vue'
import LastMarksAdmin from '@/components/LastMarksAdmin.vue'
import LastMoviesAdmin from '@/components/LastMoviesAdmin.vue'

export default { 
  name: 'adminPage',
  components: {
    Menu,
    InstallHC,
    LastPix,
    LastCommAdmin,
    LastInstallAdmin,
    LastAccountsAdmin,
    MessagesAdmin,
    SendAnEmail,
    HiddenInstallAdmin,
    LastMarksAdmin,
    LastMoviesAdmin,
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,      
      nom: '',      
      error: null,
      disabled: true,
      dest: null,
      toto: 0    
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },    
  mounted() {    
    if(this.getStatus !== null) {
      this.nom = this.getNom
      this.admin = true
      this.disabled = false
    }
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      })
    })   
  },     
  computed: {    
    getStatus: function() {
      return localStorage.getItem('admin')
    },
    getNom: function() {
      return localStorage.getItem('login')
    },
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  methods: {    
    emailAuthor: function(e) {
      //alert(e)
      this.dest = e
    },
    saveOk: function() {
      this.toto += 1
    }
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.adminPage {
  margin:0 auto;  
  width:100%;    
  padding:0;
  text-align:left;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6NJREFUeJzt20uIHEUcx/HPDkFQ9+JBBMNG4s1LVFBR2AUhGgx4EB+gZ0EQUVRUEALGPBCEaCRiQEGPelAEUYOoJ0F0FTV68iYrWQIKZqNEEZN4qAzUzvT0a7q7eh8/KNidqvpXfX/VU91VXcOmNrZmcvLmcC1mO+pLrE/wR8U6O7AdJ/Az/qzb+Bw+xPlEab/8gcnSAv6KYpzDcRzA1VXhlxPC72sAfjSdxRFcUiZYypFvAz5OX+DivGBzCeGfrwE/g7cqtnM4L+Cdawg+1l34rWRb/+GqSYHuTwC/dwrwWFcKl3iZNp+bFKRrA/bWAJ3HAxhk5F2EN0u0+21caUuFxn/Fl5W7nK2v8XLFOgs4hkvxFB7GYpT/Lx7ESTybE+d6YTL8ezSj6Ap4p2KHm1TWbH8WB2UP4n75LDuyGumrAUW3us9x2UidGRzNqbM7q6E+GlD2Pv8TrhipuwWfZpRdwTVZjfXNgCoPOUMTRq+Ey4W1QQx/86QG+2RAVfhh+sz4nHCbEvD0x4C68MN0ICPmIQXw9MOAaeGHd4cbyzaY9UCRSvF9fhoN8JqSbH0xoCn4oW7AfWUK9sGApuHhNH4pUzC1AW3B7xIetwuV0oDk8KQzoBfwpDGgN/B0b0Cv4OnWgLbgb1cNfiBaOHVlQJvwi0UFIw2EZfJC/EHb6gv8DF7FQ/GHbRvQJ/gjwjbaKrVpQJ/gD+ORrMy2DGgDfkU9+EN4bFKBNgxoC36X6vAv4om8Qm0Y8Du+azBeXfgXhO3z0mpyQ2SAJ4W9+mk2N07hpioQF/RMQdx74462oXN4SXiTc6JmjDojP1Rp05owYF7o5N3GX3IuXujM8Yoxp4GvpCYMOCbswb0n7MNvH8lfxq34qmS8OrN9bTVhQHyGaCd+FOaTWKdwB34oiDWE/6aBfpVSG3PALN42ftZnRXgltTShXufwtPskuAevWG3CSeFAwz8jZZPA0/5a4FHh7E+s7/F49H8yeLpZDe4xPie8jo8lhqe7/YA3rD6vd15YmSWFpzsDZoVRj+eDJYnh6XZLbKcwAfZKXW+KPt1xe4Xq0oDTwgKpV6pySmySpjnkmFyp3w0m16YBqTuQWhvegCqT4DzebasjDeuWsgWrGLAV91TvS7+14b8CsQGja/T1rDPDP2IDirar1pMmsn5kun38tZDez3Nmm9WHi9dbWhJ+XpOrOXzQg862MfJj8HkLma24TsFv7daAzgjf+eXUHdlUH/U/+ggB0rZwsxwAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  margin-left:2px;
  font-size: 1.1rem;
  font-weight: bold; 
  margin-top:10px; 
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

textarea {
  width:100%;
  height:20em;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.red{
  color:red;
}

.blue {
  color: #007bff;
}

input {
  width:100%;
}

</style>