<template>
  <div>    
    <div v-for="lastInstall in json" v-bind:key=lastInstall._ID class="installation shadow whitebg d-inline-block">      
      <router-link :to="{path: '/presentation/' + urlProprio(lastInstall.Proprio)}">Installation Home-Cinema de {{ lastInstall.Proprio}}</router-link>        
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default { 
  name: 'lastInstallAdmin',
  components: {
  },
  data() {         
    return {
      json: null, 
    }    
  },
  props: {
    install: {
        type: String,
        default: "10"
    }
  },  
  methods: {
    urlProprio: function(value) {
      return value.replace(/ /gi,'___')
    }
  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/admin/lastinstall/' + this.install)
    .then(response => (this.json = response.data.lastInstall))    
  }
}

</script>

<style scoped>

.installation {
  margin:5px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding:2px;
  width:320px;
  text-align: center;
}

a:hover {
  text-decoration: none;
}
</style>